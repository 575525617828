

import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { BASE_URL } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowLeft, faBell, faLightbulb, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';





function AppNavbar ({loggedIn , role , auth ,setLoggedIn , clearToken , isDarkMode, toggleDarkMode , style} ) {

  // const [scrollTop , setScrollTop] = useState(0);

  
  // const onScroll = () =>{
  //   const windowScroll = document.documentElement.scrollTop;
  //   const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  //   const scrolled = (windowScroll / height) * 100;
  //   setScrollTop(scrolled);
  // }

  //   useEffect(() => {
  //     window.addEventListener("scroll" , onScroll)
  //     return () => window.removeEventListener("scroll" , onScroll)
  //   }, [])



        
    
  // const [name, setName] = useState([]);
  // const [ role , setRole] = useState("")

  //   // الريكويست الخاص ب تحديد اذا كان أدمن ام مستخدم عادي
  //   useEffect(() => {
  //     const token = localStorage.getItem('token');
  //     const config = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         "ngrok-skip-browser-warning": "any",
  //         'Authorization': `Bearer ${token}`,
  //       }
  //     };
  //     axios.get(`${BASE_URL}/api/Auth`, config)
  //       .then(response => {
  //         setRole(response.data.role)
  //         setName(response.data);
  //       })
  //       .catch(error => {

  //       });
  //   }, [loggedIn]);

  //   const roled = localStorage.getItem("sha1");



    const [active, setActive] = useState(false);
    const [navHeight, setNavHeight] = useState('0px');

    function handleToggle() {
      setActive(!active);
    }
    const [isMenuOpen, setMenuOpen] = useState(false);

    const [isSticky, setIsSticky] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
  
    const onScroll = () => {
      const windowScroll = document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (windowScroll / height) * 100;
      setScrollTop(scrolled);
    };
  
    useEffect(() => {
      const handleScroll = () => {
        setIsSticky(window.scrollY > 0);
      };
  
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('scroll', onScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('scroll', onScroll);
      };
    }, []);
  
    
    
    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
    
    const navigate = useNavigate();

    // User Profile Dropdown
    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const handleProfileOutsideClick = (event) => {
    if (!event.target.closest('.user_img')) {
      setProfileDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isProfileDropdownOpen) {
      document.addEventListener('click', handleProfileOutsideClick);
    } else {
      document.removeEventListener('click', handleProfileOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleProfileOutsideClick);
    };
  }, [isProfileDropdownOpen]);

  // Bell Icon Dropdown
  const [isBellDropdownOpen, setBellDropdownOpen] = useState(false);

  const toggleBellDropdown = () => {
    setBellDropdownOpen(!isBellDropdownOpen);
  };

  const handleBellOutsideClick = (event) => {
    if (!event.target.closest('.bell')) {
      setBellDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isBellDropdownOpen) {
      document.addEventListener('click', handleBellOutsideClick);
    } else {
      document.removeEventListener('click', handleBellOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleBellOutsideClick);
    };
  }, [isBellDropdownOpen]);


  const [isLoading, setIsLoading] = useState(true);
    const [Noti , setNoti] = useState([]);
  const fetchDataNoti = async () => {
    const token = localStorage.getItem('token');
    const config = {
    headers: {
    'Content-Type': 'application/json',
    "ngrok-skip-browser-warning": "any",
    'Authorization': `Bearer ${token}`,
    }
    };

    try {
    const response = await axios.get(`${BASE_URL}/api/mynotifications`, config);
    setIsLoading(false); 
    setNoti(response.data.data);
    } catch (error) {
    setIsLoading(false); 
    console.log(error);
    }
    }

    useEffect(() => {
      fetchDataNoti();
    }, []);



    const isMobileDevice = useMediaQuery({ query: '(max-width: 767px)' }); // تعديل الحد حسب الحاجة

  

  

  


  const notificationCount = 5; // Replace with the actual notification count


    return (   
      <>

      <nav
      className={` nav-bar ${isSticky ? 'sticky' : ''} ${isMenuOpen ? 'open' : ''}   ${style ? 'dark-mode' : ''}`} >
 
     <div className="container">
   
      {
        loggedIn ?
        ""
        :
        <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
        <span className="bar"></span>
  <span className="bar"></span>
  <span className="bar"></span>
  {/* {isMenuOpen && <img src="/images/atom.gif" alt="" className="menu-image" loading="lazy"/>} */}
</div>
      }

<div className='d-flex align-items-center mobile-layout'>
      <div className={`dark-mode-toggle ${isDarkMode ? 'active' : ''}`} onClick={toggleDarkMode}>
  <FontAwesomeIcon icon={faLightbulb} className="lamp-icon ms-lg-2 me-2" style={{ width: '30px' }}/>
</div>
      <Link to="/" className="logo">
        <img className='dark-logo' src='/images/logo-dark..webp' alt="" loading='lazy'/>
        <img className='light-logo'  src='/images/logo-light..webp' alt="" loading='lazy'/>
        {/* <img src="/images/Transparent Version - NEW White2..webp" alt="" loading="lazy"/>
        <h3> المعطي في <span>الكيمياء</span> </h3> */}
      </Link>
    </div>
      
      <ul className={`${isMenuOpen ? 'show' : ''}`}>
          {
            loggedIn ?
            <>
            {/* {
                auth.role > 0
                ?
                ""
                :
                <Link to="/chargewallet">
                <img src="/images/wallet.png" alt="" className="wallet"/>
                </Link>               
                 
            } */}
          <div className='bell-img'>
           {/* User Profile Dropdown */}
      <img
        src="/images/profile (1)..webp"
        alt=""
        className="user_img"
        loading="lazy"
        onClick={toggleProfileDropdown}
      />
      {isProfileDropdownOpen && (
        <div className={`drop ${isProfileDropdownOpen ? 'show' : ''}`}>
          <Link>
            <span className='me-2' style={{ whiteSpace: 'nowrap' }}>{auth.fname}</span> <span className='d-flex align-items-center flex-row-reverse'>  اهلاً <span className='me-1'>وسهلاً</span> </span>
          </Link>
          <Link to="/profile" onClick={() => setProfileDropdownOpen(false)}> حسابي <img src='/images/profile-removebg-preview..webp' loading="lazy" alt="" /></Link>
          <Link onClick={clearToken}> تسجيل الخروج <img src='/images/logout-removebg-preview..webp' alt="" loading="lazy"/></Link>
        </div>
      )}

      {/* Bell Icon Dropdown */}
          <div className='div-bell'>
          <FontAwesomeIcon
        icon={faBell}
        className='bell'
        style={{ color: '#f7434e' }}
        onClick={toggleBellDropdown}
          />
            {/* {notificationCount > 0 && (
          <div className="notification-badge">
            <span>{notificationCount}</span>
          </div>
        )} */}
          </div>
        
     
          {isBellDropdownOpen && (
  <div className={`drop ${isBellDropdownOpen ? 'show' : ''}`}>
      {
        isLoading
        ?
        <Link className='no-noti' >           <Spinner animation="border" role="status" className='spinners'>
        <span className="visually-hidden">Loading...</span>
        </Spinner>  <span className='ms-1 me-1'> الاشعارات </span>  يتم تحميل بيانات</Link>

        :
        Noti.length > 0 ? (
          Noti.map((noti) => (
            <Link to={`/contentcourse/${noti.course_id}`} style={isMobileDevice ? {} : { whiteSpace: 'nowrap' }} key={noti.id}>
             <FontAwesomeIcon icon={faArrowLeft} className="back-arrow me-4" /> {/* Left arrow icon */}
              {noti.data}
            </Link>
          ))
        ) : (
          <Link className='no-noti' >  لك حتي الأن  <span className='ms-1 me-1'> اشعارات </span>  لا توجد </Link>
        )}
      
  </div>
)}

<div className='wallet'>
      <Link to={`/charge-wallet`}>
      <img src='/images/wallet.png' alt='' loading='lazy'/>
      </Link>

    </div>

            </div>  

            </>

            :
    
            <>
          <li>
          <Link to="/loginuser" className="login"> 
          <span className={`first-span ${style ? 'dark-mode' : ''}`}> تسجيل </span><span className="child-span">الدخول</span> 
          <img src="/images/next..webp" alt="" loading="lazy"/>
          </Link>
          </li>
          <li>  
          <Link to="/registeruser" className="register"> 
          <span className="text-center me-lg-3" >! انشئ حسابك</span>
          <img src="/images/add-group..webp" alt="" loading="lazy"/>
          </Link>

          </li> 
            </>

          }
      </ul>
     </div>
     
     <div className={`scroller ${isSticky ? 'sticky' : ''}`}>
        <div
          className="scrollerStyle"
          style={{ width: `${scrollTop}%` }}
        ></div>
      </div>


      <div className='image-fanos'>
          <img src='/images/tl.png' alt='' lazy="loading"/>
        </div>

    </nav>
      </>
    
    )
}



export default AppNavbar;







