import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import '../styles/registeruser.css'

import { BASE_URL } from "../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


export const Loginuser = ({setLoggedIn , loggedIn, clearToken , style }) => {
       const navigate = useNavigate();

     const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState("");

    const handleLogin = async (event) => {
      event.preventDefault();
      try {
          await axios.post(`${BASE_URL}/api/login` , {email , password})
              .then(response => {
                  const token = response.data.data.token;
                  const role = response.data.data.user.role;
                  localStorage.setItem("token", token);
                  localStorage.setItem("sha1", role);


                
                  swal({
                    title: "تم تسجيل الدخول بنجاح!",
                    icon: "success",
                    button: "حسنا!",
                  }).then(() => {
                    setLoggedIn(true);
                    // if (role > 0) {
                    //   navigate("/admin");
                    // } else {
                    //   navigate("/");
                    // }
                                          navigate("/");

                    window.location.reload();
                  });
              })
          setEmail("");
          setPassword("");
      }catch (e) {


             if(e.response.status === 422 ) {
                setErrors(e.response.data.errors)
             
              }
            else if (e.response.status === 401) {
            swal({
                title: "! خطأ في تسجيل الدخول ",
                text: "يرجي التحقق من رقم الهاتف او الباسورد",
                icon: "warning",
                button: "! اعد المحاولة",
              });
        }
        else if (e.response.status === 402) {
            swal({
                title: " هذا الحساب محظور يرجى التواصل معنا",
                text: "يرجي الرجوع إلي المساعدين",
                icon: "warning",
                button: "! فهمت ",
              });
        }
        else if (e.response.status === 403) {
            swal({
                title: " لا يمكنك تسجيل الحساب من جهاز اخر     ",
                text: "   يرجي التحقق من الجهاز",
                icon: "warning",
                button: "! فهمت ",
              });
        }
        else if (e.response.status === 405) {
            swal({
                title: "   حسابك يراجع من المساعدين    ",
                text: "   سيتم الموافقة عليه في اقرب وقت   ",
                icon: "warning",
                button: "! فهمت ",
              });
        }
    }
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    
    const [showPassword, setShowPassword] = useState(false);

    
return (
    <>
    <div className={`register-user ${style ? 'dark-mode' : ''}`}>
    <div className="register-button mb-5">
{/* <button className="login-btn" role="button">تسجيل الدخول </button> */}
    </div>

      <Container>

            <Row>
                {/* <Col lg={12} className="ms-auto me-auto"> */}
                <div className="box">
            <Col lg={6}>
            <Form onSubmit={handleLogin}>
                  <h2 className="mb-5">تسجيل <span>الدخول</span></h2>
        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label> رقم الهاتف</Form.Label>
          <Form.Control type="number" placeholder="ادخل رقم الهاتف" name="email"  onChange={(e) =>setEmail(e.target.value)}/>
          <Form.Text className="text-muted">
          {errors.email && <div className="error">{errors.email[0]}</div>}
          </Form.Text>
        </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>كلمة المرور</Form.Label>
        <Form.Control type="password" placeholder="ادخل كلمة المرور" name="password" required onChange={(e) =>setPassword(e.target.value)}/>
        <Form.Text className="text-muted">
        {errors.password && <div className="error">{errors.password[0]}</div>}
        </Form.Text>
      </Form.Group>

 
       <Button variant="outline-primary" className="mt-3 mb-3" type="submit">تسجيل الدخول</Button>{' '} */}
       

          <div>
          <label className="mb-2 ms-3 label">رقم الهاتف</label>
       <input type="number" placeholder="ادخل رقم الهاتف" name="email"  onChange={(e) =>setEmail(e.target.value)}/>
       {errors.email && <div className="error">{errors.email[0]}</div>}

          </div>

          <div  className="mt-4">
          <label className="mb-2 ms-3 label"> كلمة المرور</label>
          <div className="password-input">
        <input       type={showPassword ? 'text' : 'password'} // Toggle password visibility
  placeholder=" ادخل كلمة المرور" 
  name="password"
   required 
   onChange={(e) =>setPassword(e.target.value)}/>
       <span className="password-toggle mt-5" onClick={() => setShowPassword(!showPassword)}>
      {/* <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /> */}
    </span>
        </div>
       <p>  {errors.password && <div className="error">{errors.password[0]}</div>}</p>

          </div>
      {/* <Link className="register">!انشئ حسابك الأن</Link> */}
      <button type="submit" className="mt-4">تسجيل الدخول</button>
            </Form>
            </Col>
                <Col lg={6}>
                <div className="welcome-sign">
                    <div className="content text-center">
                      <h2> , اهلا بك في منصة <span className="d-block mt-2">   Dr Yehia Ebrahim</span> </h2>
                      <p className="mt-4">لو عندك ايميل سجل خلاص لو معندكش اعمل ايميل من جديد</p>
                      <Link className="mt-4 d-block"  to="/registeruser"> !  انشئ حسابك الأن </Link>
                    </div>
                  </div>
                </Col>
            
                </div>
                {/* </Col> */}
            </Row>
        </Container>
    </div>
    </>
)
}

export default Loginuser;